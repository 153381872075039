<!-- © 2021 Tfarraj -->
<template>
  <div id="video-details" class="pa-4">
    <v-container>
      <!-- <div class="d-flex justify-space-between mb-5"> -->
      <v-btn text small class="pl-0" @click="$router.go(-1)">
        <v-icon left> mdi-arrow-left </v-icon>
        {{ $t('video.channelVideos') }}
      </v-btn>
      <h2 class="mt-5">
        {{ $t('videoDetails.videoDetails') }}
      </h2>
      <v-row>
        <v-col cols="12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
              <ValidationProvider
                v-slot="{ errors }"
                name="Title"
                rules="required|min:3"
              >
                <v-text-field
                  v-model="formData.title"
                  :loading="inputLoading"
                  :error-messages="
                    errors != '' ? $t('videoDetails.' + errors) : ''
                  "
                  :label="$t('videoDetails.title')"
                  class="mb-3"
                  filled
                  dense
                  counter="100"
                  max-length="100"
                />
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" name="Description">
                <v-textarea
                  v-model="formData.description"
                  :loading="inputLoading"
                  filled
                  auto-grow
                  :error-messages="
                    errors != '' ? $t('videoDetails.' + errors) : ''
                  "
                  :label="$t('videoDetails.description')"
                  :placeholder="$t('videoDetails.tellViewers')"
                  rows="5"
                  counter="5000"
                  max-length="5000"
                  row-height="20"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Visibility"
                rules="required|oneOf:private,public,unlisted"
              >
                <v-select
                  v-model="formData.visibility"
                  :loading="inputLoading"
                  :items="videoVisibility"
                  :error-messages="
                    errors != '' ? $t('videoDetails.' + errors) : ''
                  "
                  filled
                  :placeholder="$t('videoDetails.visibility')"
                  :label="$t('videoDetails.visibility')"
                  item-value="key"
                  item-text="value"
                >
                  <template #selection="{ item }">
                    {{ $t('visibility.' + item.value) }}
                  </template>
                  <template #item="{ item }">
                    {{ $t('visibility.' + item.value) }}
                  </template>
                </v-select>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Language"
                rules="required"
              >
                <v-autocomplete
                  v-model="formData.contentLanguage"
                  :items="contentLanguage"
                  item-text="value"
                  item-value="key"
                  :error-messages="
                    errors != '' ? $t('videoDetails.' + errors) : ''
                  "
                  filled
                  :placeholder="$t('videoDetails.language')"
                  :label="$t('videoDetails.language')"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Category"
                rules="required"
              >
                <v-autocomplete
                  v-model="formData.category"
                  :items="categories"
                  item-text="value"
                  item-value="key"
                  :error-messages="
                    errors != '' ? $t('videoDetails.' + errors) : ''
                  "
                  :placeholder="$t('videoDetails.categories')"
                  :label="$t('videoDetails.categories')"
                  :loading="categoryLoading"
                />
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" name="Tags">
                <v-combobox
                  multiple
                  v-model="formData.tags"
                  :placeholder="$t('videoDetails.tags')"
                  :label="$t('videoDetails.tags')"
                  append-icon
                  chips
                  :delimiters="[',']"
                  deletable-chips
                  :search-input.sync="tags"
                  @update:search-input="updateTags"
                />
              </ValidationProvider>
              <!-- <v-btn @click="toggleShow">
                {{$t("videoDetails.uploadNewThumbnail")}}</v-btn> -->
              <my-upload
                v-model="show"
                field="thumbnail"
                method="PUT"
                :width="640"
                :height="360"
                :url="url"
                :headers="headers"
                img-format="jpg"
                lang-type="en"
                @crop-success="cropSuccess"
              />
              <v-responsive style="max-width: 100%">
                <div v-if="!imgDataUrl" id="image-placeholder" class="px-12">
                  <v-icon>mdi-image-plus</v-icon>
                </div>
                <v-img v-else width="200" :src="imgDataUrl"></v-img>
              </v-responsive>
              <div class="mt-6 d-flex justify-space-between">
                <v-btn
                  :loading="submitLoading"
                  type="submit"
                  class="primary"
                  depressed
                >
                  {{ $t('videoDetails.submit') }}
                </v-btn>
              </div>
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import Config from '@/config';
import VideoService from '@/services/VideoService';
import CategoryService from '@/services/CategoryService';
import FormsMixin from '@/mixins/FormsMixin.ts';
import CommonMixin from '@/mixins/CommonMixin.ts';
import { mapGetters } from 'vuex';

export default {
  name: 'Details',
  components: {
    myUpload,
  },
  mixins: [FormsMixin, CommonMixin],
  data() {
    return {
      // dialog: this.openDialog ? this.openDialog : false,
      inputLoading: false,
      submitLoading: false,
      categoryLoading: false,
      value: 0,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size < 5159367048 ||
          'Video size should be less than 5 GB!',
      ],
      categoriesData: [],
      categories: [],
      tags: '',
      contentLanguage: [
        { key: 'ar', value: 'Arabic' },
        { key: 'da', value: 'Danish' },
        { key: 'nl', value: 'Dutch' },
        { key: 'en', value: 'English' },
        { key: 'fi', value: 'Finnish' },
        { key: 'fr', value: 'French' },
        { key: 'de', value: 'German' },
        { key: 'hu', value: 'Hungarian' },
        { key: 'it', value: 'Italian' },
        { key: 'no', value: 'Norwegian' },
        { key: 'pt', value: 'Portuguese' },
        { key: 'ro', value: 'Romanian' },
        { key: 'ru', value: 'Russian' },
        { key: 'es', value: 'Spanish' },
        { key: 'sv', value: 'Swedish' },
        { key: 'tr', value: 'Turkish' },
      ],
      formData: {
        title: '',
        description: '',
        category: '',
        visibility: '',
        contentLanguage: '',
        tags: [],
      },
      // imgDataUrl: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',

      imgDataUrl: '',
      imgDataObj: [],
      url: '',
      headers: { Authorization: `Bearer ${this.$store.getters.getToken}` },
    };
  },
  computed: {
    ...mapGetters(['getDisplayLanguage']),
  },
  watch: {
    getDisplayLanguage() {
      this.updateCategoriesLanguage();
    },
  },
  mounted() {
    this.getVideo();
    this.getCategories();
  },
  methods: {
    async getVideo() {
      this.inputLoading = true;
      let video = await VideoService.getById(this.$route.params.id)
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.inputLoading = false;
        });

      if (!video) return;
      video = video.data;
      this.url = `${Config.apiUrl}/api/v1/videos/${video._id}/thumbnail`;
      this.formData.title = video.title;
      this.formData.description = video.description;
      this.formData.visibility = video.visibility;
      this.formData.contentLanguage = video.contentLanguage;
      this.formData.category = video.category._id;
      this.formData.tags = video.tags;
      this.imgDataUrl = `${Config.apiUrl}/api/v1/videos/${video.id}/thumbnail`;
    },
    async submit() {
      // if (this.$route.name === 'Dashboard')
      this.submitLoading = true;
      const video = await VideoService.updateVideo(this.$route.params.id, {
        title: this.formData.title,
        description: this.formData.description,
        category: this.formData.category,
        visibility: this.formData.visibility,
        contentLanguage: this.formData.contentLanguage,
        //thumbnail: this.imgDataObj.name,
        tags: this.formData.tags,
      })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => (this.submitLoading = false));

      if (!video) return;

      this.$router.push('/studio/videos');
    },
    async getCategories() {
      this.categoryLoading = true;
      const categoriesResponse = await CategoryService.getAll()
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => (this.categoryLoading = false));
      this.categoriesData = categoriesResponse.data.data;
      this.updateCategoriesLanguage();
    },
    updateCategoriesLanguage() {
      this.categories = this.categoriesData.map((category) => ({
        key: category._id,
        value:
          this.displayLanguage === 'en' ? category.nameEn : category.nameAr,
      }));
    },
    toggleShow() {
      this.show = !this.show;
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async cropSuccess(imgDataUrl, field) {
      const file = this.dataURLtoBlob(imgDataUrl);
      this.imgDataObj = file;
      this.imgDataUrl = imgDataUrl;
    },
  },
};
</script>

<style lang="scss">
.card {
  background: black !important;
}
</style>
