<!-- © 2021 Tfarraj -->
<template>
  <div id="watch" ref="watch">
    <v-container fluid>
      <v-row>
        <v-alert prominent class="mx-auto" type="error" v-if="errored">
          <v-row align="center">
            <v-col class="grow">
              <div class="title">Well...</div>
              <div>This video may have been removed by the user.</div>
            </v-col>
            <v-col class="shrink">
              <v-btn @click="goToHome()">Take Action</v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-col v-else cols="0" class="mx-auto">
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-skeleton-loader
                type="card-avatar, article, actions"
                :loading="videoLoading"
                tile
                small
              >
                <div ref="watchLoad">
                  <v-responsive>
                    <div class="shaka">
                      <video-player
                        ref="videoLoad"
                        :license-server="`https://widevine-proxy.appspot.com/proxy`"
                        :manifest-url="vurl"
                        :poster-url="`${apiUrl}/api/v1/videos/${video.id}/thumbnail`"
                        :content-title="this.video.title"
                        :page-url="currentUrl"
                        :ua-status="isMobile()"
                        :vid-id="vidId"
                        :duration="durationSeconds"
                        :vcat-name="vcat"
                        :view="this.view"
                      />
                    </div>
                  </v-responsive>

                  <v-card flat tile class="card">
                    <v-card-title class="pl-0 pb-0"
                      ><div class="pr-5" v-snip="2">{{ this.video.title }}</div>

                      <v-badge
                        v-if="this.video.stage === 'live'"
                        bordered
                        color="indigo darken-4"
                        icon="mdi-movie-open"
                        overlap
                      >
                        <v-btn
                          x-small
                          class="white--text"
                          color="red"
                          depressed
                        >
                          Live
                        </v-btn>
                      </v-badge>

                      <v-layout row wrap justify-end>
                        <v-flex shrink>
                          <v-btn
                            v-if="!isMobile()"
                            @click="fullScreenMode = !fullScreenMode"
                            elevation="2"
                            x-small
                            >{{
                              !fullScreenMode
                                ? $t('watch.theater')
                                : $t('watch.browser')
                            }}</v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                    <span
                      class="pl-0 pt-0 pb-0 subtitle-2"
                      style="line-height: 1.4em"
                    >
                      #{{ this.channel.title }}
                    </span>
                    <template>
                      &nbsp;&nbsp;
                      <ShareNetwork
                        network="twitter"
                        :url="`${uiUrl}${currentUrl}`"
                        :title="this.video.title"
                        :description="this.video.description"
                        :twitter-user="twitterUser"
                      >
                        <i class="fab fa-1x fa-twitter"></i>
                      </ShareNetwork>
                      &nbsp;
                      <ShareNetwork
                        network="facebook"
                        :url="`${uiUrl}${currentUrl}`"
                        :title="this.video.title"
                        :description="this.video.description"
                        :quote="`${this.video.title}, video via ${siteName}`"
                        :hashtags="siteName"
                      >
                        <i class="fab fa-1x fa-facebook"></i>
                      </ShareNetwork>
                      &nbsp;
                      <a @click="embedCodeModal">
                        <i class="fab fa-solid fa-code"></i>
                      </a>
                      &nbsp;
                    </template>
                    <div
                      class="d-flex flex-wrap justify-space-between"
                      id="btns"
                    >
                      <v-card-subtitle
                        class="pl-0 pt-0 pb-0 subtitle-1"
                        style="line-height: 2.4em"
                      >
                        {{ video.views }} {{ $t('watch.views')
                        }}<v-icon>mdi-circle-small</v-icon>
                        {{ formatDate(video.createdAt) }}
                      </v-card-subtitle>
                      <v-card-actions class="pt-0 pl-0">
                        <v-btn text @click="submitFeeling('like')">
                          <v-icon
                            :class="`pr-2${
                              feeling.feeling !== 'like'
                                ? ' grey--text text--darken-1'
                                : ''
                            }`"
                            >mdi-thumb-up</v-icon
                          >{{ video.likes }}
                        </v-btn>

                        <v-btn
                          v-if="this.video.stage !== 'live'"
                          :href="vurl"
                          text
                          class="grey--text text--darken-1"
                        >
                          <v-icon>mdi-download</v-icon>
                          {{ $t('watch.download') }}
                        </v-btn>
                        <v-btn
                          v-else
                          text
                          disabled
                          class="grey--text text--darken-1"
                        >
                          Live Stream
                        </v-btn>

                        <!-- <v-btn text class="grey--text text--darken-1"
                             ><v-icon>mdi-share</v-icon> Share</v-btn
                             >
                             <v-btn text class="grey--text text--darken-1"
                             ><v-icon>mdi-playlist-plus</v-icon> Save</v-btn
                             > -->

                        <template fluid>
                          <div>
                            <v-btn
                              color="indigo darken-4"
                              elevation="2"
                              x-small
                              @click="loadRocketChat()"
                              :style="{
                                backgroundColor: isHidden
                                  ? 'grey !important'
                                  : '',
                                color: isHidden ? 'white !important' : 'white',
                              }"
                              >{{ $t('watch.chat') }}</v-btn
                            >
                          </div>
                          <div class="mr-3 ml-3">
                            <v-btn
                              elevation="2"
                              x-small
                              @click="submitReport"
                              :style="{
                                backgroundColor: 'grey',
                                color: 'white',
                              }"
                              >{{ $t('report.report') }}</v-btn
                            >
                          </div>
                        </template>
                      </v-card-actions>
                    </div>
                  </v-card>
                  <v-row class="justify-space-between">
                    <v-col cols="12" sm="6" md="5" lg="5">
                      <div
                        v-if="isMobile()"
                        :style="{
                          display: isHidden ? 'none' : 'block',
                        }"
                      >
                        <iframe
                          ref="rc"
                          class="mb-3"
                          height="600"
                          width="100%"
                          frameBorder="0"
                          @load="rcAuth"
                          :src="`${chatUrl}/channel/general?layout=embedded`"
                          id="rchat"
                        ></iframe>
                      </div>
                      <v-card class="transparent" flat>
                        <v-list-item
                          v-if="typeof video.channel !== 'undefined'"
                          :to="`/channels/${this.video.channel.id}`"
                        >
                          <v-list-item-avatar
                            v-if="typeof video.createdBy !== 'undefined'"
                            size="50"
                          >
                            <img-with-auth
                              v-if="video.channel.photo"
                              :id="this.video.channel.id"
                              :type="'channel'"
                            />
                            <v-avatar v-else color="indigo darken-4">
                              <span class="white--text headline">
                                {{
                                  video.channel.title.split('')[0].toUpperCase()
                                }}</span
                              >
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content
                            v-if="video.createdBy"
                            class="align-self-auto"
                          >
                            <v-list-item-title
                              class="font-weight-medium mb-1"
                              >{{ video.channel.title }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >{{ video.channel.subscribers }}
                              {{ $t('watch.subscribers') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4">
                      <div
                        class="d-flex justify-end align-center"
                        v-if="typeof video.createdBy !== 'undefined'"
                      >
                        <v-btn
                          v-if="getUser && video.createdBy !== getUser._id"
                          :class="[
                            { 'red white--text': !subscribed },
                            {
                              'grey grey--text lighten-3 text--darken-3':
                                subscribed,
                            },
                            'mt-6',
                          ]"
                          tile
                          x-small
                          depressed
                          :loading="subscribeLoading"
                          @click="subscribe"
                          >{{
                            !subscribed
                              ? $t('watch.subscribe')
                              : $t('watch.subscribed')
                          }}</v-btn
                        >

                        <v-btn
                          v-else-if="showSubBtn"
                          :class="[
                            { 'red white--text': !subscribed },
                            {
                              'grey grey--text lighten-3 text--darken-3':
                                subscribed,
                            },
                            'mt-6',
                          ]"
                          tile
                          x-small
                          depressed
                          :loading="subscribeLoading"
                          @click="subscribe"
                          >{{
                            !subscribed
                              ? $t('watch.subscribe')
                              : $t('watch.subscribed')
                          }}</v-btn
                        >

                        <!-- <v-btn
                             v-if="
                             video.createdBy && video.createdBy._id !== getUser._id
                             "
                             :class="[
                             { 'red white--text': !subscribed },
                             {
                             'grey grey--text lighten-3 text--darken-3': subscribed
                             },
                             'mt-6'
                             ]"
                             tile
                             large
                             depressed
                             :loading="subscribeLoading"
                             @click="subscribe"
                             >{{ !subscribed ? 'subscribe' : 'subscribed' }}</v-btn
                             > -->
                        <!-- <v-btn icon class="ml-5 mt-6"
                             ><v-icon>mdi-bell</v-icon></v-btn
                             > -->
                      </div>
                    </v-col>
                    <v-col class="pl-11" offset="1" cols="11" md="11">
                      <p>
                        {{
                          truncate
                            ? truncateText(video.description, 150)
                            : video.description
                        }}
                      </p>
                      <v-btn text @click="show" class="remove-hover-bg">
                        {{ $t('watch.showMore') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-skeleton-loader>

              <v-row>
                <v-col v-if="video && video.visibility === 'public'">
                  <p class="mb-0">
                    {{ video.comments }} {{ $t('watch.comments') }}
                  </p>

                  <AddComment
                    @videoCommentLength="video.comments++"
                    :video="video"
                  />
                  <CommentList
                    @videoCommentLength="video.comments--"
                    :video="video"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <hr class="grey--text" />
              <div
                v-if="!isMobile()"
                :style="{
                  display: isHidden ? 'none' : 'block',
                }"
              >
                <h4 class="mb-0 mt-4">Channel Chat</h4>
                <iframe
                  ref="rc"
                  class="mb-4"
                  height="600"
                  width="100%"
                  frameBorder="0"
                  @load="rcAuth"
                  :src="`${chatUrl}/channel/general?layout=embedded`"
                  id="rchat"
                ></iframe>
              </div>
              <hr class="grey--text" />
              <h4 class="mb-2 mt-4">
                {{ $t('watch.recommended') }}
              </h4>
              <div
                v-for="(video, i) in loading ? 8 : videos"
                :key="i"
                class="mb-1"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-three-line"
                  tile
                  large
                >
                  <v-card
                    class="card"
                    tile
                    flat
                    v-if="!loading"
                    :to="`/watch/${video._id}`"
                  >
                    <v-row>
                      <v-col class="mx-auto" cols="12" sm="12" md="5" lg="5">
                        <!-- <v-responsive max-height="100%"> -->
                        <v-img
                          class="align-center"
                          contain
                          aspect-ratio="1.7778"
                          :src="`${apiUrl}/api/v1/videos/${video.id}/thumbnail`"
                        >
                        </v-img>
                        <!-- </v-responsive> -->
                      </v-col>
                      <v-col>
                        <div class="ml-2">
                          <v-card-title
                            class="pl-2 pt-0 subtitle-1 font-weight-bold"
                            style="line-height: 1"
                          >
                            <span v-snip="2">
                              {{ video.title }}
                            </span>
                          </v-card-title>

                          <v-card-subtitle
                            class="pl-2 pt-2 pb-0"
                            style="line-height: 1"
                          >
                            {{ video.channel.title }}<br />
                            {{ video.views }} {{ $t('watch.views')
                            }}<v-icon>mdi-circle-small</v-icon>
                            {{ formatDate(video.createdAt) }}
                          </v-card-subtitle>
                          <div v-if="video.stage === 'live'">
                            <v-badge
                              bordered
                              color="indigo darken-4"
                              icon="mdi-movie-open"
                              overlap
                            >
                              <v-btn
                                x-small
                                class="white--text"
                                color="red"
                                depressed
                              >
                                Live
                              </v-btn>
                            </v-badge>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-skeleton-loader>
              </div>
              <v-col class="text-center" v-if="videos.length === 0 && !loading">
                <p>No videos yet</p>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="12" lg="12"> -->
              <infinite-loading :identifier="infiniteId" @infinite="getVideos">
                <div slot="spinner">
                  <v-progress-circular
                    indeterminate
                    color="indigo darken-4"
                  ></v-progress-circular>
                </div>
                <div slot="no-results"></div>
                <span slot="no-more"></span>
                <div slot="error" slot-scope="{ trigger }">
                  <v-alert prominent type="error">
                    <v-row align="center">
                      <v-col class="grow">
                        <div class="title">oops!</div>
                        <div>
                          Something went wrong, but don’t fret — let’s give it
                          another shot.
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn @click="trigger">Take Action</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
              </infinite-loading>
              <!-- </v-col> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <embed-code-modal
      v-if="embedCodeDialog"
      :open-dialog="embedCodeDialog"
      v-on:closeDialog="embedCodeDialog = false"
      :videoId="video.id"
    />
    <report-modal
      :open-dialog="reportDialog"
      v-on:closeDialog="reportDialog = false"
      :videoId="video.id"
    />
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import Config from '@/config';
import VideoService from '@/services/VideoService';
import SubscriptionService from '@/services/SubscriptionService';
import DialogModal from '@/modals/DialogModal';
import ChannelService from '@/services/ChannelService';
import AddComment from '@/components/comments/AddComment';
import CommentList from '@/components/comments/CommentList';
import VideoPlayer from '@/components/VideoPlayer.vue';
import ImgWithAuth from '@/components/ImgWithAuth';
import DateMixin from '@/mixins/DateMixin.ts';
import EmbedCodeModal from '@/modals/EmbedCodeModal';
import ReportModal from '@/modals/ReportModal';

// const str = '';
// const cat = '';
// const n = '';
// const u = '';
// const m = '';
let stage = '';
// const mp4Path = '';

export default {
  mixins: [DateMixin],
  data: () => ({
    loading: false,
    loaded: false,
    errored: false,
    videoLoading: true,
    subscribed: false,
    subscribeLoading: false,
    showSubBtn: true,
    isHidden: true,
    feeling: {},
    video: {},
    channel: {},
    view: {},
    videoId: '',
    videos: [],
    page: 0,
    infiniteId: +new Date(),
    truncate: true,
    currentUrl: '',
    siteName: Config.siteName,
    uiUrl: Config.uiUrl,
    apiUrl: Config.apiUrl,
    chatUrl: '',
    twitterUser: Config.twitterUser,
    vurl: '',
    vidId: '',
    durationSeconds: '',
    vcat: '',
    sStatus: '',
    showDialog: false,
    details: {},
    fullScreenMode: false,
    embedCodeDialog: false,
    reportDialog: false,
  }),
  created() {
    this.updateChatUrl();
  },
  watch: {
    fullScreenMode() {
      if (this.fullScreenMode === true) {
        this.openFullscreen();
      } else {
        this.closeFullscreen();
      }
    },
    $route: 'updateChatUrl',
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUrl',
      'getIsAuthenticated',
      'getIsUserActive',
      'getSubscribedChannels',
      'getChannel',
      'getChatToken',
    ]),
  },
  methods: {
    updateChatUrl() {
      this.chatUrl = Config.chatUrl;
      this.isHidden = true;
    },
    openFullscreen() {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },

    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    embedCodeModal() {
      this.embedCodeDialog = true;
    },
    async getVideo(id) {
      this.errored = false;
      this.videoLoading = true;
      this.video = {};
      this.feeling = {};
      try {
        const video = await VideoService.getById(id);
        this.vidId = id;

        if (!video) return this.$router.push('/');
        this.video = video.data;
        this.channel = video.data.channel;
        stage = this.video.stage;
        this.durationSeconds = video.data.durationSeconds;
        this.vurl = `${this.apiUrl}/api/v1/videos/${id}/video`;

        this.currentUrl = window.location.pathname;
        if (stage === 'live') {
          this.sStatus = 'Live';
        } else {
          this.sStatus = '';
        }
      } catch (err) {
        this.errored = true;
      } finally {
        await this.checkSubscription(this.channel.id);
        await this.getVideoFeeling(this.video._id);
        await this.getMostRecentView(this.video._id);

        if (Object.keys(this.view).length !== 0) {
          localStorage.setItem(
            this.video._id,
            (this.view.watchPercentage * this.video.durationSeconds) / 100,
          );
        } else {
          await this.createView(this.video._id, 0);
        }

        this.videoLoading = false;
        this.checkSubscription(this.video.channel.id);
        // this.checkFeeling(this.video.id);
        // Added to enable counts for self reload of Watch Page.
        // See Repeat Below. Causes Double count on Arrival from other pages.
        // Still cannot be implemented to count views when not logged in.
      }
      if (this.getUser && this.getUser.id === this.video.createdBy) {
        this.showSubBtn = false;
      } else {
        this.showSubBtn = true;
      }

      if (!this.getIsAuthenticated) return;

      if (
        this.video.createdBy.toString() !== this.video.createdBy.toString() &&
        this.video.status !== 'public'
      )
        return this.$router.push('/');

      // const data = {
      //   type: 'watch',
      //   videoId: this.video._id,
      // };

      // videoElement = this.$refs.videoLoad;
      // console.log(JSON.stringify(videoElement));

      // Print the native height of the video
      // console.log("videoHeight: " + videoElement.videoHeight);

      // Print the native width of the video
      // console.log("videoWidth: " + videoElement.videoWidth);

      // await HistoryService.createHistory(data).catch((err) => console.log(err));
      // await ViewService.createHistory(data).catch((err) => console.log(err));
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        return true;
      }
      return false;
    },
    loadRocketChat() {
      this.isHidden = !this.isHidden;
    },
    async getVideos($state) {
      this.errored = false;
      if (!this.loaded) {
        this.loading = true;
      }
      if (this.page > 0) {
        const videos = await VideoService.getAll({ page: this.page })
          .catch((err) => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });

        if (typeof videos === 'undefined') return;

        if (videos.data.data.length) {
          this.page += 1;
          this.videos.push(...videos.data.data);
          $state.loaded();
          this.loaded = true;
        } else {
          $state.complete();
        }
      } else {
        const videos = await VideoService.getRecommended()
          .catch((err) => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });

        if (typeof videos === 'undefined') return;

        videos.data.data.forEach((data) => {
          if (data.videos.length) {
            this.page += 1;
            this.videos.push(...data.videos);
            $state.loaded();
            this.loaded = true;
          } else {
            $state.complete();
          }
        });
      }
    },
    async checkSubscription(id) {
      if (!this.getIsAuthenticated) return;

      const sub = this.getSubscribedChannels.find(
        (channel) => channel.channel === id || channel.id === id,
      );
      if (!sub) return;

      if (!(sub.channel || sub.id)) this.subscribed = false;
      else this.subscribed = true;
    },
    async getVideoFeeling(id) {
      if (!this.getIsAuthenticated) return;

      this.loading = true;
      const feeling = await VideoService.getVideoFeeling(id)
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });

      if (!feeling) return;

      if (feeling.data.feeling === 'like') this.feeling.feeling = 'like';
    },
    async createVideoFeeling(type) {
      const feeling = await VideoService.setVideoFeeling(this.video._id, {
        feeling: type,
      }).catch((err) => {});
      if (!feeling) return;
    },
    async deleteVideoFeeling() {
      const feeling = await VideoService.deleteVideoFeeling(
        this.video._id,
      ).catch((err) => {
        // console.log(err);
      });
      if (!feeling) return;
    },
    async submitFeeling(type) {
      if (!this.getIsAuthenticated) {
        this.showDialog = true;
        this.details = {
          title:
            type === 'like'
              ? this.$i18n.t('watch.likeThisVideo')
              : this.$i18n.t('watch.dontLikeThisVideo'),
          text: this.$i18n.t('watch.signInToMakeYourOpinionCount'),
          buttonText: 'signin',
        };
        return;
      }
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
      switch (true) {
        case type === 'like' && this.feeling.feeling === undefined:
          if (this.createVideoFeeling(type)) {
            this.feeling.feeling = 'like';
            this.video.likes++;
          }

          break;
        case type === 'like' && this.feeling.feeling === type:
          if (this.deleteVideoFeeling()) {
            this.feeling.feeling = undefined;
            this.video.likes--;
          }
          break;
      }

      // const feeling = await FeelingService.createVideoFeeling(this.video._id, {
      //   feeling: type,
      // }).catch((err) => {
      //   console.log(err);
      // });

      // if (!feeling) return;
    },
    async submitReport() {
      if (!this.getIsAuthenticated) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('watch.notSignedToReport'),
          text: this.$i18n.t('watch.signinToReport'),
          buttonText: 'signin',
        };
        return;
      }
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
      this.reportDialog = true;
    },
    async createView(id, watchPercentage) {
      if (!this.getIsAuthenticated) {
        return;
      }
      const view = await VideoService.createView(id, {
        watchPercentage,
      }).catch((err) => {
        // console.log(err);
      });
      if (!view) return;

      this.view = view.data;
    },
    async getMostRecentView(id) {
      if (!this.getIsAuthenticated) {
        return;
      }
      const view = await VideoService.getMostRecentVideoView(id).catch(
        (err) => {
          // console.log(err);
        },
      );
      if (!view.data) {
        this.view = {};
        return;
      }
      this.view = view.data;

      // if (this.view) {
      localStorage.setItem(
        id,
        (this.view.watchPercentage * this.video.durationSeconds) / 100,
      );
      // } else {
      //   await this.createView(this.video._id, 0);
      //   localStorage.setItem(this.video._id, 0);
      // }
    },
    async subscribe() {
      if (!this.getIsAuthenticated) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('watch.wantToSubscribeToThisChannel'),
          text: this.$i18n.t('watch.signInToSubscribeToThisChannel'),
          buttonText: 'signin',
        };
        return;
      }
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
      this.subscribeLoading = true;
      if (this.subscribed) {
        const sub = await SubscriptionService.removeSubscription(
          this.video.channel.id,
        )
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.subscribeLoading = false;
          });

        if (!sub) return;
        if (sub.data._id) {
          this.subscribed = false;
          let subscribedChannels = [];
          subscribedChannels = this.getSubscribedChannels.filter(
            (subscribedChannel) => subscribedChannel._id !== sub.data.channel,
          );
          localStorage.setItem(
            'subscribedChannels',
            JSON.stringify(subscribedChannels),
          );
          this.$store.commit('SET_SUBSCRIBED_CHANNEL_DATA', subscribedChannels);
        }
      } else {
        const sub = await SubscriptionService.createSubscription(
          this.getChannel.id,
          this.channel._id,
        )
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.subscribeLoading = false;
          });

        if (!sub) return;
        if (sub.data._id) {
          this.subscribed = true;
          const subscribedChannels = [];
          subscribedChannels.push(...this.getSubscribedChannels);
          const channel = await ChannelService.getChannelById(sub.data.channel);
          subscribedChannels.push(channel.data);
          localStorage.setItem(
            'subscribedChannels',
            JSON.stringify(subscribedChannels),
          );
          this.$store.commit('SET_SUBSCRIBED_CHANNEL_DATA', subscribedChannels);
        }
      }
    },
    async rcAuth() {
      // If Authenticated login user to chat via commands
      if (this.getIsAuthenticated) {
        const { rc } = this.$refs;
        rc.contentWindow.postMessage(
          {
            externalCommand: 'login-with-token',
            token: this.getChatToken,
          },
          Config.chatUrl,
        );
      }
      // Regardless of user is loggedin or not redirect them
      // to the chat of this videos channel
      window.addEventListener('message', (e) => {
        if (e.data.eventName === 'room-opened') {
          const rc = document.querySelector('#rchat');
          if (e.data.data.name !== this.video.channel.title) {
            rc.contentWindow.postMessage(
              {
                externalCommand: 'go',
                path: `/channel/${this.video.channel.title}?layout=embedded`,
              },
              Config.chatUrl,
            );
          }
        }
      });
    },
    play(e) {
      // console.log(e);
    },
    actions() {
      this.getVideo();
    },
    goToHome() {
      this.$router.push('/');
    },
    show(event) {
      if (event.target.innerText === 'SHOW MORE') {
        this.truncate = false;
        event.target.innerText = 'SHOW LESS';
      } else {
        this.truncate = true;
        event.target.innerText = 'SHOW MORE';
      }
    },
    truncateText(string = '', num) {
      if (string.length <= num) {
        return string;
      }
      return string.slice(0, num);
    },
  },
  components: {
    AddComment,
    CommentList,
    DialogModal,
    InfiniteLoading,
    VideoPlayer,
    ImgWithAuth,
    EmbedCodeModal,
    ReportModal,
  },
  mounted() {
    this.getVideo(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.page = 0;
    (this.loading = false), (this.loaded = false), (this.videos = []);
    this.infiniteId += 1;
    this.getVideo(to.params.id);
    next();
  },
};
</script>

<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
/*
     .v-dialog:not(.v-dialog--fullscreen) {
     bottom: 0 !important;
     right: 0 !important;
     position: absolute !important;
     }
   */

/* video {
     max-width: 100%;
     } */

.streamstatus {
  color: #bd0404 !important;
  padding-left: 10px;
  /*padding-right: 10px;
       border-style:solid;
       border-color:#bd0404;*/
}

.grey-color {
  color: #7f7f7f !important;
}

#btns {
  border-bottom: 1px solid #e0d8d8;
}

button.v-btn.remove-hover-bg {
  background-color: initial !important;

  &:hover {
    background-color: #f9f9f9;
  }
}

.shaka {
  margin: 0 auto;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: top;
  text-align: center;
  background-color: #000000;
}
.shaka-controls-container {
  opacity: 1;
}
.shaka-video-container {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.max-w-full {
  max-width: 100%;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}
.video-ratio {
  aspect-ratio: 16 / 9;
}
</style>
