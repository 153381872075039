// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  createReply(id, data) {
    // return Api().post('comments', data)
    return Api().post(`videos/${id}/comments`, data);
  },
  deleteById(id) {
    return Api().delete(`replies/${id}`);
  },
};
