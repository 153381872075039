<!-- © 2021 Tfarraj -->
<template>
  <div>
    <v-dialog
      eager
      v-model="dialog"
      persistent
      transition="fab-transition"
      max-width="800"
    >
      <v-card tile>
        <div class="d-flex justify-space-between mb-5" id="modal-header">
          <v-card-title class="py-3">{{
            $t('embedCodeModal.embedVideo')
          }}</v-card-title>
          <v-btn icon text @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="d-flex ml-2 mr-2">
          <v-text-field
            ref="generatedCodeField"
            v-model="generatedCode"
            filled
            readonly
            dense
          >
          </v-text-field>
          <v-btn color="indigo darken-4" @click="copyText" class="mt-1">{{
            $t('embedCodeModal.copy')
          }}</v-btn>
        </div>
        <v-layout justify-center class="pb-5">
          <iframe
            width="560"
            height="315"
            ref="frame"
            :src="videoUrl"
            title="Tfarraj video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          >
          </iframe>
        </v-layout>
        <div class="justify-center"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Config from '@/config';

export default {
  name: 'EmbedCodeModal',
  props: {
    openDialog: {
      type: Boolean,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      videoUrl: `${Config.uiUrl}/embed/${this.videoId}`,
    };
  },
  computed: {
    dialog() {
      return this.openDialog;
    },
    generatedCode() {
      return `<iframe width="560" height="315" src="${this.videoUrl}"  title="Tfarraj video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    },
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.generatedCode);
    },
    closeModal() {
      this.$emit('closeDialog');
    },
  },
};
</script>
