// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  search(data, params) {
    return Api().post('search', data, {
      params,
    });
  },
  getAll() {
    return Api().get('search');
  },
  deleteById(id) {
    return Api().delete(`search/${id}`);
  },
  deleteAll() {
    return Api().delete('search');
  },
};
