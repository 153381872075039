<!-- © 2021 Tfarraj -->
<template>
  <div id="history" class="fill-height">
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="7"
          class="pt-8 pl-8"
        >
          <h1 class="title font-weight-medium pb-5">
            {{ this.$i18n.t('history.historyType') }}
          </h1>
          <template v-if="historyType == 'watchHistory'">
            <template v-if="views.length <= 0 && !loading">
              <p class="text-center body-1">
                {{ $t('history.noWatchHistoryYet') }}
              </p>
            </template>
            <section>
              <div
                v-for="(view, i) in loading ? 12 : views"
                :key="i"
                class="mb-5"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-three-line"
                  :loading="loading"
                  tile
                  large
                >
                  <v-card class="card" tile flat>
                    <v-row no-gutters v-if="view.video">
                      <v-col class="mx-auto" cols="3" sm="3" md="5" lg="5">
                        <router-link :to="`/watch/${view.video.id}`">
                          <img-with-auth
                            class="align-center"
                            width="100%"
                            height="100%"
                            :id="view.video.id"
                            :type="'video'"
                          >
                          </img-with-auth>
                        </router-link>
                      </v-col>
                      <v-col>
                        <div class="ml-2">
                          <v-card-title
                            class="
                              pl-2
                              pt-0
                              subtitle-1
                              font-weight-bold
                              d-flex
                              justify-space-between
                            "
                            style="line-height: 1"
                          >
                            {{ view.video.title | truncate(25, '...') }}

                            <v-btn
                              text
                              @click="DeleteVideoFromHistory(view.video._id)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-subtitle
                            class="pl-2 pt-2 pb-0"
                            style="line-height: 1"
                          >
                            {{ view.video.channel.title }}
                          </v-card-subtitle>
                          <v-card-subtitle
                            class="pl-2 pt-2 pb-0"
                            style="line-height: 1"
                          >
                            {{ view.video.views || 0 }}
                            {{ $t('history.views') }}
                          </v-card-subtitle>
                          <v-card-subtitle class="pl-2 pt-2 pb-0">
                            {{ view.video.description }}
                          </v-card-subtitle>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-skeleton-loader>
              </div>
              <infinite-loading :identifier="infiniteId" @infinite="getViews">
                <div slot="spinner">
                  <v-progress-circular
                    indeterminate
                    color="indigo darken-4"
                  ></v-progress-circular>
                </div>
                <div slot="no-results"></div>
                <span slot="no-more"></span>
                <div slot="error" slot-scope="{ trigger }">
                  <v-alert prominent type="error">
                    <v-row align="center">
                      <v-col class="grow">
                        <div class="title">Error!</div>
                        <div>
                          Something went wrong, but don’t fret — let’s give it
                          another shot.
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn @click="trigger">{{
                          $t('history.takeAction')
                        }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
              </infinite-loading>
            </section>
          </template>
          <template v-else>
            <template v-if="searches.length <= 0 && !loading">
              <p class="text-center body-1">
                {{ $t('history.noSearchHistoryYet') }}
              </p>
            </template>
            <div>
              <div v-for="(search, i) in loading ? 12 : searches" :key="i">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-two-line"
                  :loading="loading"
                  tile
                  large
                >
                  <v-card class="card d-flex pl-0" flat>
                    <v-card-text class="pl-0 py-0">
                      <v-card-title
                        class="
                          pl-0
                          white--text
                          text--lighten-5
                          subtitle-1
                          font-weight-medium
                        "
                        >{{ search.searchText }}</v-card-title
                      >
                      <v-card-subtitle class="pl-0 pb-0">
                        {{ formatDate(search.createdAt) }}
                      </v-card-subtitle>
                    </v-card-text>
                    <v-card-actions
                      ><v-btn
                        text
                        class="grey--text"
                        fab
                        @click="DeleteSearchFromHistory(search._id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn></v-card-actions
                    >
                  </v-card>
                </v-skeleton-loader>
              </div>
              <infinite-loading @infinite="getSearches">
                <div slot="spinner">
                  <v-progress-circular
                    indeterminate
                    color="indigo darken-4"
                  ></v-progress-circular>
                </div>
                <div slot="no-results"></div>
                <span slot="no-more"></span>
                <div slot="error" slot-scope="{ trigger }">
                  <v-alert prominent type="error">
                    <v-row align="center">
                      <v-col class="grow">
                        <div class="title">Error!</div>
                        <div>
                          Something went wrong, but don’t fret — let’s give it
                          another shot.
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn @click="trigger">Take action</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
              </infinite-loading>
            </div>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"
          md="5"
          lg="5"
          :class="[
            'pa-0',
            {
              'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false,
            },
          ]"
        >
          <v-card
            flat
            id="card-radiobox"
            class="fill-height grey lighten-4 pa-10"
          >
            <v-radio-group v-model="historyType">
              <p class="title font-weight-regular pl-5 mb-2">
                {{ $t('history.historyType') }}
              </p>
              <v-list class="grey lighten-4">
                <v-list-item-group>
                  <template v-for="(item, i) in items">
                    <v-divider :key="i"></v-divider>
                    <v-list-item
                      active-class="grey lighten-4"
                      :key="`item-${i}`"
                      class="py-2"
                      @click="clickItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t('history.' + item)
                        }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-radio :key="item" :value="item"></v-radio>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <div>
                <v-btn text :loading="clearLoading" @click="clearHistory">
                  {{ $t('history.clearAll') }}
                  {{ this.$i18n.t('history.' + this.historyType) }}
                </v-btn>
                <!-- <v-btn text>Pause {{ historyType }}</v-btn>
                <v-btn text>Manage all activity</v-btn> -->
              </div>
            </v-radio-group>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ deleteMessage }}
      <v-btn color="white" text @click="snackbar = false" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import SearchService from '@/services/SearchService';
import ViewService from '@/services/ViewService';
import VideoService from '@/services/VideoService';
import ImgWithAuth from '@/components/ImgWithAuth';
import DateMixin from '@/mixins/DateMixin.ts';
import DialogModal from '@/modals/DialogModal';

export default {
  mixins: [DateMixin],
  data: () => ({
    loading: false,
    loaded: false,
    errored: false,
    snackbar: false,
    deleteMessage: '',
    items: ['watchHistory', 'searchHistory'],
    historyType: 'watchHistory',
    views: [],
    searches: [],
    showDialog: false,
    details: {},
    page: 1,
    infiniteId: +new Date(),
    clearLoading: false,
  }),
  computed: {
    ...mapGetters(['getUser', 'getUrl', 'getIsUserActive']),
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
  },
  methods: {
    async getSearches($state) {
      this.errored = false;
      if (!this.loaded) {
        this.loading = true;
      }
      const searches = await SearchService.getAll()
        .catch((err) => {
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
      if (!searches) return;
      this.searches.push(...searches.data);
      if ($state) {
        $state.loaded();
        $state.complete();
      }
      this.loaded = true;
    },
    async getViews($state) {
      this.errored = false;
      if (!this.loaded) {
        this.loading = true;
      }

      const params = {
        page: this.page,
        type: this.historyType === 'watchHistory' ? 'watch' : 'search',
      };

      const views = await ViewService.getViews(params)
        .catch((err) => {
          this.errored = true;
          // console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
      if ($state) {
        $state.loaded();
        $state.complete();
      }
      this.loaded = true;

      if (!views) return;
      if (views.data.data.length) {
        this.page += 1;

        views.data.data.forEach((view) => {
          const tmpView = view;
          tmpView.type = 'watchHistory';
          this.views.push(tmpView);
        });

        if ($state) {
          $state.loaded();
        }

        this.loaded = true;
      } else if ($state) {
        $state.complete();
      }
    },
    async clearHistory() {
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }

      this.clearLoading = true;

      try {
        if (this.historyType === 'searchHistory') {
          await SearchService.deleteAll();
          this.searches = [];
        } else if (this.historyType === 'watchHistory') {
          await ViewService.deleteAll();
          this.views = [];
        }
        this.deleteMessage = `${this.$i18n.t(
          `history.${this.historyType}`,
        )} ${this.$i18n.t('history.cleared')}`;
      } catch (err) {
        this.deleteMessage = this.$i18n.t('history.errorOccured');
      } finally {
        this.clearLoading = false;
        this.snackbar = true;
      }
    },
    async DeleteSearchFromHistory(id) {
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }

      this.clearLoading = true;

      try {
        await SearchService.deleteById(id);
        this.searches = this.searches.filter(
          (search) => search._id.toString() !== id.toString(),
        );
        this.deleteMessage = this.$i18n.t(
          'history.searchHistoryDeletedSuccessfully',
        );
      } catch (err) {
        this.deleteMessage = this.$i18n.t('history.errorOccured');
      } finally {
        this.snackbar = true;
        this.clearLoading = false;
      }
    },
    async DeleteVideoFromHistory(id) {
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }

      this.clearLoading = true;

      try {
        await VideoService.deleteVideoViews(id);
        this.views = this.views.filter(
          (view) => view.video._id.toString() !== id.toString(),
        );
        this.deleteMessage = this.$i18n.t(
          'history.watchHistoryDeletedSuccessfully',
        );
      } catch (err) {
        this.deleteMessage = this.$i18n.t('history.errorOccured');
      } finally {
        this.snackbar = true;
        this.clearLoading = false;
      }
    },
    clickItem(item) {
      this.historyType = item;
      this.page = 1;
      this.searches = [];
      this.views = [];
      this.infiniteId += 1;
    },
  },
  components: {
    InfiniteLoading,
    ImgWithAuth,
    DialogModal,
  },
  mounted() {
    this.getViews();
    this.getSearches();
  },
};
</script>

<style lang="scss" scoped></style>
