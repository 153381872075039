<!-- © 2021 Tfarraj -->
<template>
  <v-card class="transparent" flat>
    <v-list-item v-if="!video.isBlockingUserChannel" three-line class="pl-0">
      <v-list-item-avatar size="50">
        <v-avatar v-if="!getIsAuthenticated" color="indigo darken-4">
          <v-icon class="white--text">mdi-account</v-icon>
        </v-avatar>
        <v-avatar v-else-if="getChannel.photo" color="indigo darken-4">
          <v-img
            :src="`${getUrl}/api/v1/channels/${getChannel._id}/photo`"
            :alt="`${getChannel.title} avatar`"
          ></v-img>
        </v-avatar>
        <v-avatar v-else color="indigo darken-4">
          <span class="white--text headline">
            {{ getChannel.title.split('')[0].toUpperCase() }}</span
          >
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="align-self-auto">
        <v-text-field
          v-model="comment"
          v-bind:label="$t('addComment.addComment')"
          @click="clickTextField"
        >
        </v-text-field>
        <div v-if="showCommentBtns" class="d-inline-block text-right">
          <v-btn text @click="showCommentBtns = !showCommentBtns">{{
            $t('video.cancel')
          }}</v-btn>
          <v-btn
            class="grey darken-3 white--text"
            depressed
            tile
            :loading="loading"
            :disabled="comment === ''"
            @click="createComment"
            >{{ $t('video.comment') }}</v-btn
          >
        </div>
      </v-list-item-content>
    </v-list-item>
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import CommentService from '@/services/CommentService';
import DialogModal from '@/modals/DialogModal';

export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCommentBtns: false,
      loading: false,
      comment: '',
      showDialog: false,
      details: {},
    };
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getChannel',
      'getUrl',
      'getIsAuthenticated',
      'getIsUserActive',
    ]),
  },
  methods: {
    async createComment() {
      if (this.comment === '') return;

      this.loading = true;
      const comment = await CommentService.createComment(this.video._id, {
        text: this.comment,
        channel: this.$store.getters.getChannel,
        // replyTo:""
      })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });

      if (!comment) return;
      this.comment = '';
      comment.data.replies = [];
      comment.data.userId = this.$store.getters.getUser;

      this.$store.dispatch('addComment', comment.data);
      this.$emit('videoCommentLength');
    },
    clickTextField() {
      if (!this.getIsAuthenticated) {
        return this.$router.push('/signin');
      }
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
      this.showCommentBtns = true;
    },
  },
  components: {
    DialogModal,
  },
};
</script>

<style></style>
