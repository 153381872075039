<!-- © 2021 Tfarraj -->
<template>
  <div v-if="comments.data.length > 0">
    <div v-for="(comment, i) in loading ? 4 : comments.data">
      <v-skeleton-loader type="list-item-avatar-two-line" :loading="loading">
        <v-card class="transparent" flat>
          <v-list-item three-line class="pl-0 mt-2">
            <v-list-item-avatar size="50">
              <v-avatar v-if="comment.channel.photo">
                <img
                  :src="`${getUrl}/api/v1/channels/${comment.channel.id}/photo`"
                  :alt="`${comment.channel.title} avatar`"
                />
              </v-avatar>
              <v-avatar v-else color="indigo darken-4">
                <span
                  v-if="typeof comment.channel.title !== 'undefined'"
                  class="white--text headline"
                >
                  {{ comment.channel.title.split('')[0].toUpperCase() }}
                </span>
                <span v-else class="white--text headline">
                  {{ getChannel.title.split('')[0].toUpperCase() }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="d-flex mb\-0">
                <v-list-item-title
                  v-if="comment.channel"
                  class="font-weight-medium caption mb-0 d-flex"
                  >{{ comment.channel.title }}
                  <span class="pl-2 pr-2 font-weight-light grey--text">
                    {{ formatDate(comment.createdAt) }}</span
                  >
                </v-list-item-title>
                <v-menu
                  bottom
                  left
                  v-if="
                    getIsAuthenticated &&
                    (getChannel.id === video.channel.id ||
                      getChannel.id === comment.channel.id)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="deleteComment(comment._id)">
                      <v-list-item-title
                        ><v-icon>mdi-trash</v-icon
                        >{{ $t('commentList.delete') }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-list-item-subtitle
                class="mt-n2 white--text text--darken-3 caption"
                >{{ comment.text }}</v-list-item-subtitle
              >

              <div>
                <v-btn
                  v-if="!video.isBlockingUserChannel"
                  class="grey darken-3 white--text"
                  text
                  x-small
                  :ripple="false"
                  @click.stop="showReply(`${'reply' + comment._id}`)"
                  >{{ $t('commentList.reply') }}</v-btn
                >
              </div>
              <div class="d-none" :ref="`${'reply' + comment._id}`">
                <v-list-item three-line class="pl-0">
                  <v-list-item-avatar class="mt-2" size="40">
                    <v-avatar
                      v-if="!getIsAuthenticated"
                      color="indigo darken-4"
                    >
                      <v-icon class="white--text">mdi-account</v-icon>
                    </v-avatar>
                    <template v-else>
                      <v-avatar v-if="getChannel.photo">
                        <img
                          :src="`${getUrl}/api/v1/channels/${getChannel.id}/photo`"
                          :alt="`${getChannel.title} avatar`"
                        />
                      </v-avatar>
                      <v-avatar v-else color="indigo darken-4">
                        <span class="white--text headline">
                          {{ getChannel.title.split('')[0].toUpperCase() }}
                        </span>
                      </v-avatar>
                    </template>
                  </v-list-item-avatar>
                  <v-list-item-content class="align-self-auto mt-0 pt-0">
                    <v-form :ref="`form${comment._id}`">
                      <v-text-field
                        :ref="`${'input' + comment._id}`"
                        class="pt-0 mt-0 body-2"
                        v-bind:label="$t('commentList.addComment')"
                        @click="clickTextField"
                        :value="repliesInput[`input${comment._id}`]"
                      >
                      </v-text-field>
                    </v-form>
                    <div
                      :ref="comment._id + 'btns'"
                      class="d-inline-block text-right"
                      v-if="getIsAuthenticated && getIsUserActive"
                    >
                      <v-btn text @click="hideReply(comment._id)" x-small>
                        {{ $t('commentList.cancel') }}
                      </v-btn>
                      <v-btn
                        class="grey darken-3 white--text"
                        depressed
                        tile
                        x-small
                        :loading="btnLoading && i == index"
                        @click="
                          index = i;
                          addReply($event, comment._id);
                        "
                      >
                        {{ $t('commentList.reply') }}</v-btn
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <!-- </v-list-item-action> -->
              </div>
              <v-expansion-panels>
                <v-expansion-panel class="transparent elevation-0">
                  <v-expansion-panel-header
                    v-if="comment.replies && comment.replies.length > 0"
                    class="indigo--text text--darken-3 py-0"
                  >
                    {{ comment.replies.length }} {{ $t('commentList.replies') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list-item
                      three-line
                      class="pl-0 mt-2"
                      v-for="reply in comment.replies"
                      :key="reply._id"
                    >
                      <v-list-item-avatar
                        v-if="typeof reply !== 'undefined'"
                        size="50"
                      >
                        <v-avatar v-if="reply.channel.photo">
                          <img
                            :src="`${getUrl}/api/v1/channels/${reply.channel.id}/photo`"
                            :alt="`${reply.channel.title} avatar`"
                          />
                        </v-avatar>
                        <v-avatar v-else color="indigo darken-4">
                          <span
                            v-if="typeof reply.channel.title !== 'undefined'"
                            class="white--text headline"
                          >
                            {{ reply.channel.title.split('')[0].toUpperCase() }}
                          </span>
                          <span v-else class="white--text headline">
                            {{ getChannel.title.split('')[0].toUpperCase() }}
                          </span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div class="d-flex mb-0">
                          <v-list-item-title
                            v-if="reply.userId"
                            class="font-weight-medium caption mb-0 d-flex"
                            >{{ reply.userId.channelName }}
                            <span class="pl-2 font-weight-light grey--text">
                              {{ formatDate(reply.createdAt) }}
                            </span>
                          </v-list-item-title>
                          <v-menu bottom left v-if="getIsAuthenticated">
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item @click="deleteComment(reply._id)">
                                <v-list-item-title
                                  ><v-icon>mdi-trash</v-icon
                                  >{{
                                    $t('commentList.delete')
                                  }}</v-list-item-title
                                >
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <v-list-item-subtitle
                          class="mt-n2 white--text text--darken-3 caption"
                          >{{ reply.text }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-skeleton-loader>
    </div>
    <v-snackbar v-model="snackbar">
      {{ $t('commentList.deletedSuccessfully') }}
      <v-btn color="white" text @click="snackbar = false" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar v-model="unAuthMessage">
      {{ $t('commentList.unauthorizedToDelete') }}
      <v-btn color="white" text @click="unAuthMessage = false" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import CommentService from '@/services/CommentService';
import ReplyService from '@/services/ReplyService';
import DateMixin from '@/mixins/DateMixin.ts';
import DialogModal from '@/modals/DialogModal';

export default {
  mixins: [DateMixin],
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    repliesInput: {},
    comments: { data: [] },
    commentsLength: false,
    index: -1,
    btnLoading: false,
    url: Config.apiUrl,
    snackbar: false,
    unAuthMessage: false,
    loading: false,
    showDialog: false,
    details: {},
  }),
  computed: {
    ...mapGetters([
      'getIsAuthenticated',
      'getIsUserActive',
      'getUser',
      'getChannel',
      'getUrl',
    ]),
  },
  methods: {
    async getComments() {
      this.loading = true;
      const comments = await this.$store
        .dispatch('setComments', this.video._id)
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => (this.loading = false));
      if (!comments) return;
      this.comments = this.$store.getters.getComments.data;
    },
    async deleteComment(id) {
      if (!this.getIsAuthenticated) return;
      // this.$store.dispatch('deleteComment', id)
      this.comments = this.comments.data.filter(
        (comment) => comment._id.toString() !== id.toString(),
      );

      this.snackbar = true;
      await CommentService.deleteById(id).catch((err) => {
        this.snackbar = false;
        this.unAuthMessage = true;
      });

      await this.$store.dispatch('setComments', this.video._id).catch((err) => {
        // console.log(err)
      });
      this.comments = this.$store.getters.getComments.data;
      this.$emit('videoCommentLength');
    },
    async addReply(event, id) {
      if (!this.getIsAuthenticated) return;
      if (this.$refs[`input${id}`][0].$refs.input.value === '') return;

      this.btnLoading = true;
      this.$refs[`form${id}`][0].reset();

      const reply = await ReplyService.createReply(this.video._id, {
        channel: this.$store.getters.getChannel.id,
        text: this.$refs[`input${id}`][0].$refs.input.value,
        replyTo: id,
      })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          // this.$store.dispatch('setComments', this.videoId)
        });
      reply.data.userId = this.$store.getters.getUser;
      // this.$store.dispatch('addComment', reply.data.data)
      const comment = this.comments.data.find(
        (comment) => comment._id.toString() === id.toString(),
      );
      if (!comment.replies) {
        // comment.replies = []
        comment.replies.push(reply.data);
      } else {
        comment.replies.unshift(reply.data);
        // this.comments
        //   .find((comment) => comment._id === id)
        //   .replies.unshift(reply.data.data)
      }

      // console.log(
      //   this.$store.getters.getComments.data.find(
      //     (comment) => comment._id === id
      //   )
      // )
      // this.comments
      //   .find((comment) => comment._id === id)
      //   .replies.unshift(reply.data.data)
    },
    clickTextField() {
      if (!this.getIsAuthenticated) return this.$router.push('/signin');
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
    },
    showReply(id) {
      this.$refs[id][0].classList.toggle('d-none');
    },
    hideReply(id) {
      this.$refs[`form${id}`][0].reset();
      this.$refs[`reply${id}`][0].classList.toggle('d-none');
    },
  },
  components: {
    DialogModal,
  },
  async mounted() {
    await this.getComments();
  },
};
</script>

<style lang="scss">
.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: initial;
}

.v-expansion-panel::before {
  box-shadow: initial;
}
</style>
