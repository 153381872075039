<!-- © 2021 Tfarraj -->
<template>
  <div id="video" class="pa-4 pl-0 fill-height">
    <!-- <v-container fluid> -->
    <h2>{{ $t('video.channelVideos') }}</h2>

    <v-container fluid>
      <v-row class="fill-height">
        <v-col
          cols="1"
          order="1"
          order-sm="1"
          order-md="1"
          order-lg="1"
          sm="9"
          md="9"
          lg="9"
          class="pt-8 pl-8"
        >
          <!-- <v-row> -->
          <v-tabs v-model="tab" id="tab" class="mt-5">
            <v-tab>{{ $t('video.uploads') }} </v-tab>
            <v-tab>{{ $t('video.streams') }} </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Video Tab -->
            <v-tab-item ref="vodTab">
              <template>
                <v-card flat>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      v-bind:placeholder="$t('video.filters')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    v-bind:no-data-text="$t('video.noVideosAvailable')"
                    :headers="headers"
                    :items="videos.filter((v) => v.source === 'upload')"
                    :search="search"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :footer-props="{
                      'items-per-page-options': [5, 10, 15, -1],
                    }"
                    :server-items-length="itemsLength"
                    :items-per-page="5"
                    @update:items-per-page="updateRowsPerPageItems"
                    @pagination="updatePage"
                  >
                    <template v-slot:item.image="{ item }">
                      <div style="position: relative">
                        <img-with-auth
                          width="100px"
                          height="56px"
                          class="align-center my-2"
                          :id="item.id"
                          :type="'video'"
                        />
                        <div
                          v-if="item.stage === 'encoding'"
                          style="
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            background-color: black;
                            border-radius: 100%;
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="orange"
                            class="bi bi-cloud-arrow-up-fill float-sm-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"
                            />
                          </svg>
                        </div>
                        <div
                          v-else-if="item.reportingStatus === 'banned'"
                          style="position: absolute; bottom: 3px; right: 8px"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="red"
                            viewBox="-2.4 -2.4 28.80 28.80"
                            style="
                              background-color: red;
                              border-radius: 100%;
                              width: 17px;
                              height: 17px;
                            "
                          >
                            <path
                              d="M18.364 18.364C19.9926 16.7353 21 14.4853 21 12C21 7.02944 16.9706 3 12 3C9.51472 3 7.26472 4.00736 5.63604 5.63604M18.364 18.364C16.7353 19.9926 14.4853 21 12 21C7.02944 21 3 16.9706 3 12C3 9.51472 4.00736 7.26472 5.63604 5.63604M18.364 18.364L5.63604 5.63604"
                              stroke="#000000"
                              stroke-width="3.4"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div
                          v-else-if="item.reportingStatus === 'reported'"
                          style="position: absolute; bottom: 3px; right: 8px"
                        >
                          <svg
                            style="
                              padding: 1px;
                              background-color: yellow;
                              border-radius: 100%;
                              width: 17px;
                              height: 17px;
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            fill="black"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M15 6H9M20 21H19M19 21H5M19 21C19 18.4898 17.7877 16.1341 15.7451 14.675L12 12M5 21H4M5 21C5 18.4898 6.21228 16.1341 8.25493 14.675L12 12M20 3H19M19 3H5M19 3C19 5.51022 17.7877 7.86592 15.7451 9.32495L12 12M5 3H4M5 3C5 5.51022 6.21228 7.86592 8.25493 9.32495L12 12"
                              stroke="black"
                              stroke-width="2.9"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div
                          v-else-if="item.reportingStatus === 'new'"
                          style="position: absolute; bottom: 3px; right: 8px"
                        >
                          <svg
                            style="
                              padding: 1px;
                              background-color: yellowgreen;
                              border-radius: 100%;
                              width: 17px;
                              height: 17px;
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            fill="black"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M15 6H9M20 21H19M19 21H5M19 21C19 18.4898 17.7877 16.1341 15.7451 14.675L12 12M5 21H4M5 21C5 18.4898 6.21228 16.1341 8.25493 14.675L12 12M20 3H19M19 3H5M19 3C19 5.51022 17.7877 7.86592 15.7451 9.32495L12 12M5 3H4M5 3C5 5.51022 6.21228 7.86592 8.25493 9.32495L12 12"
                              stroke="black"
                              stroke-width="2.9"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div
                          v-else
                          style="
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            background-color: black;
                            border-radius: 100%;
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="green"
                            class="bi bi-check-circle-fill float-sm-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                            />
                          </svg>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.visibility="{ item }">
                      <div>
                        {{ $t('visibility.' + item.visibility) }}
                      </div>
                    </template>
                    <template v-slot:item.titleComponent="{ item }">
                      <div>
                        {{ item.title }}
                      </div>
                    </template>
                    <template v-slot:item.views="{ item }">
                      {{ item.views ? item.views : 0 }}
                    </template>
                    <template v-slot:item.comments="{ item }">
                      {{ item.comments ? item.comments : 0 }}
                    </template>
                    <template v-slot:item.feelings="{ item }">
                      <span class="mr-3">{{ item.likes }}</span>
                    </template>
                    <template v-slot:item.reportingStatus="{ item }">
                      <span v-if="item.stage === 'encoding'" class="mr-3">{{
                        item.reportingStatus
                          ? $i18n.t('videoStatus.encoding')
                          : ''
                      }}</span>
                      <span v-else class="mr-3">{{
                        item.reportingStatus
                          ? $i18n.t('videoStatus.' + item.reportingStatus)
                          : ''
                      }}</span>
                    </template>
                    <template v-slot:top>
                      <v-dialog
                        v-model="dialogDelete"
                        persistent
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{
                              $t('video.permanentlyDelete')
                            }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-card class="card" tile flat>
                                <v-row no-gutters>
                                  <v-col cols="3" sm="2" md="5" lg="5">
                                    <img-with-auth
                                      class="align-center"
                                      width="100%"
                                      height="100%"
                                      :id="itemToDelete.id"
                                      :type="'video'"
                                    />
                                  </v-col>
                                  <v-col>
                                    <div class="ml-2">
                                      <v-card-title
                                        class="pl-2 subtitle-1 font-weight-bold"
                                        style="line-height: 1"
                                      >
                                        {{ itemToDelete.title }}
                                      </v-card-title>

                                      <v-card-subtitle
                                        class="pl-2 pt-2 pb-0"
                                        style="line-height: 1"
                                      >
                                        {{ $t('video.published') }}
                                        {{ itemToDelete.createdAt }}
                                        <br />
                                        {{ itemToDelete.views }}
                                        {{ $t('video.views') }}
                                      </v-card-subtitle>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="indigo darken-4"
                              text
                              @click="dialogDelete = !dialogDelete"
                              >{{ $t('video.cancel') }}</v-btn
                            >

                            <v-btn
                              :loading="deleteBtnLoading"
                              color="indigo darken-4"
                              text
                              @click="deleteItem"
                              >{{ $t('video.deleteForever') }}</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon href text class="mr-2">
                        <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        href
                        text
                        class="mr-2"
                        router
                        :to="`/watch/${item._id}`"
                      >
                        <v-icon> mdi-youtube </v-icon>
                      </v-btn>
                      <v-btn icon text @click.stop="deleteBtn(item)">
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-tab-item>
            <!-- Live Tab -->
            <v-tab-item ref="liveTab">
              <template>
                <v-card flat>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      v-bind:placeholder="$t('video.filters')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    v-bind:no-data-text="$t('video.noStreamsAvailable')"
                    :headers="headers"
                    :items="videos.filter((v) => v.source === 'live-stream')"
                    :search="search"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  >
                    <template v-slot:item.image="{ item }">
                      <img-with-auth
                        width="100px"
                        height="56px"
                        class="align-center my-2"
                        :id="item.id"
                        :type="'video'"
                      />
                    </template>
                    <template v-slot:item.titleComponent="{ item }">
                      <div>
                        {{ item.title }}
                        <v-badge
                          color="red darken-2"
                          content="Live"
                          class="ml-2"
                          v-if="item.source === 'live-stream'"
                        ></v-badge>
                      </div>
                    </template>
                    <template v-slot:item.views="{ item }">
                      {{ item.views ? item.views : 0 }}
                    </template>
                    <template v-slot:item.comments="{ item }">
                      {{ item.comments ? item.comments : 0 }}
                    </template>
                    <template v-slot:item.feelings="{ item }">
                      <span class="mr-3">{{ item.likes }}</span>
                      >
                    </template>
                    <template v-slot:top>
                      <v-dialog
                        v-model="dialogDelete"
                        persistent
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{
                              $t('video.permanentlyDelete')
                            }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-card class="card" tile flat>
                                <v-row no-gutters>
                                  <v-col cols="3" sm="2" md="5" lg="5">
                                    <img-with-auth
                                      width="100%"
                                      height="100%"
                                      class="align-center"
                                      :id="itemToDelete.id"
                                      :type="'video'"
                                    />
                                  </v-col>
                                  <v-col>
                                    <div class="ml-2">
                                      <v-card-title
                                        class="pl-2 subtitle-1 font-weight-bold"
                                        style="line-height: 1"
                                      >
                                        {{ itemToDelete.title }}
                                      </v-card-title>

                                      <v-card-subtitle
                                        class="pl-2 pt-2 pb-0"
                                        style="line-height: 1"
                                      >
                                        {{ $t('video.published') }}
                                        {{ itemToDelete.createdAt }}
                                        <br />
                                        {{ itemToDelete.views }}
                                        {{ $t('video.views') }}
                                      </v-card-subtitle>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="indigo darken-4"
                              text
                              @click="dialogDelete = !dialogDelete"
                              >{{ $t('video.cancel') }}</v-btn
                            >

                            <v-btn
                              :loading="deleteBtnLoading"
                              color="indigo darken-4"
                              text
                              @click="deleteItem"
                              >{{ $t('video.deleteForever') }}</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon href text class="mr-2">
                        <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        href
                        text
                        class="mr-2"
                        router
                        :to="`/watch/${item._id}`"
                      >
                        <v-icon> mdi-youtube </v-icon>
                      </v-btn>
                      <v-btn icon text @click.stop="deleteBtn(item)">
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
          cols="1"
          order="2"
          order-sm="2"
          order-md="2"
          order-lg="2"
          sm="3"
          md="3"
          lg="3"
          class="pt-3 pl-3"
        >
          <v-card
            flat
            id="card-radiobox"
            class="fill-height grey lighten-4 pa-10"
          >
            <v-select
              :items="[(all = 'all'), ...duration]"
              filled
              value="all"
              v-bind:label="$t('videoFilters.duration.duration')"
              item-value="key"
              item-text="value"
              @change="updateFilter($event, 'duration')"
            >
              <template v-slot:selection="{ item }">
                {{ $t('videoFilters.duration.' + (item.value || 'all')) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t('videoFilters.duration.' + (item.value || 'all')) }}
              </template>
            </v-select>
            <v-select
              :items="[(all = 'all'), ...orientation]"
              filled
              value="all"
              v-bind:label="$t('videoFilters.orientation.orientation')"
              item-value="key"
              item-text="value"
              @change="updateFilter($event, 'orientation')"
            >
              <template v-slot:selection="{ item }">
                {{ $t('videoFilters.orientation.' + (item.value || 'all')) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t('videoFilters.orientation.' + (item.value || 'all')) }}
              </template>
            </v-select>
            <v-select
              :items="[(all = 'all'), ...stage]"
              filled
              value="all"
              v-bind:label="$t('videoFilters.stage.stage')"
              item-value="key"
              item-text="value"
              @change="updateFilter($event, 'stage')"
            >
              <template v-slot:selection="{ item }">
                {{ $t('videoFilters.stage.' + (item.value || 'all')) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t('videoFilters.stage.' + (item.value || 'all')) }}
              </template>
            </v-select>
            <v-select
              :items="[(all = 'all'), ...draft]"
              filled
              value="all"
              v-bind:label="$t('videoFilters.draft.isDraft')"
              item-value="key"
              item-text="value"
              @change="updateFilter($event, 'draft')"
            >
              <template v-slot:selection="{ item }">
                {{ $t('videoFilters.draft.' + (item.value || 'all')) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t('videoFilters.draft.' + (item.value || 'all')) }}
              </template>
            </v-select>
            <v-select
              :items="[(all = 'all'), ...videoVisibility]"
              filled
              value="all"
              v-bind:label="$t('videoFilters.visibility.visibility')"
              item-value="key"
              item-text="value"
              @change="updateFilter($event, 'visibility')"
            >
              <template v-slot:selection="{ item }">
                {{ $t('visibility.' + (item.value || 'all')) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t('visibility.' + (item.value || 'all')) }}
              </template>
            </v-select>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar">
      {{ $t('video.videoDeletedSuccessfully') }}
      <v-btn color="white" text @click="snackbar = false" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import VideoService from '@/services/VideoService';
import ChannelService from '@/services/ChannelService';
import ImgWithAuth from '@/components/ImgWithAuth';
import DateMixin from '@/mixins/DateMixin.ts';
import CommonMixin from '@/mixins/CommonMixin.ts';

export default {
  mixins: [DateMixin, CommonMixin],
  data() {
    return {
      loading: false,
      deleteBtnLoading: false,
      snackbar: false,
      dialogDelete: false,
      tab: null,
      url: Config.apiUrl,
      videos: [],
      itemToDelete: {},
      filters: {},
      pageSize: 5,
      itemsLength: 0,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getUrl', 'getChannel', 'getUser']),
    headers() {
      return [
        { text: this.$i18n.t('video.thumbnail'), value: 'image' },
        {
          text: this.$i18n.t('video.video'),
          align: 'start',
          value: 'title',
        },
        { text: this.$i18n.t('video.visibility'), value: 'visibility' },
        { text: this.$i18n.t('video.views'), value: 'views' },
        { text: this.$i18n.t('video.comments'), value: 'comments' },
        { text: this.$i18n.t('video.feelings'), value: 'feelings' },
        {
          text: this.$i18n.t('videoStatus.reportingStatus'),
          value: 'reportingStatus',
        },
        {
          text: this.$i18n.t('video.actions'),
          value: 'actions',
          sortable: false,
        },
      ];
    },
    search: {
      get() {
        return this.$store.state.studioSearchText;
      },
      set(searchText) {
        this.$store.commit('setStudioSearchText', searchText);
      },
    },
  },
  methods: {
    async getVideos() {
      this.loading = true;
      const videos = await ChannelService.getStudioVideos(this.getChannel.id, {
        page: this.page,
        limit: this.pageSize,
        ...this.filters,
      })
        .catch((err) => {})
        .finally(() => (this.loading = false));
      if (!videos) return;
      this.videos = videos.data.data;
      this.itemsLength = videos.data.count;
    },
    editItem(item) {
      this.$router.push({ name: 'Detail', params: { id: item.id } });
    },
    deleteBtn(item) {
      const video = item;
      this.dialogDelete = true;
      video.createdAt = this.formatDate(video.createdAt);
      this.itemToDelete = video;
    },
    async deleteItem() {
      this.deleteBtnLoading = true;
      await VideoService.deleteById(this.itemToDelete._id)
        .catch((err) => {})
        .finally(() => {
          this.videos = this.videos.filter(
            (video) => this.itemToDelete.id !== video.id,
          );
          this.deleteBtnLoading = false;
          this.dialogDelete = false;
          this.itemToDelete = {};
          this.snackbar = true;
        });
    },
    updateFilter(value, prop) {
      if (value !== 'all') {
        this.filters[prop] = value;
      } else {
        this.filters[prop] = null;
      }
      this.getVideos();
    },
    async updateRowsPerPageItems(val) {
      this.pageSize = val === -1 ? 0 : val;
      await this.getVideos();
    },
    async updatePage(val) {
      this.page = val.page;
      await this.getVideos();
    },
  },
  components: {
    ImgWithAuth,
  },
  mounted() {
    this.getVideos();
    this.search = this.$route.query['search-query'];
  },
  beforeRouteUpdate(to, from, next) {
    this.getVideos();
    next();
  },
};
</script>

<style lang="scss">
// .card,
// #tab .v-tabs-bar {
//   background: black !important;;
// }
</style>
